import React, {FC} from "react";
import {SubscriptionClient} from "subscriptions-transport-ws";
import {WISHLIST_PRISMA_TOKEN} from "../constants";

import {
  cacheExchange,
  createClient,
  dedupExchange,
  defaultExchanges,
  fetchExchange,
  Provider,
  subscriptionExchange
} from "urql";

const subscriptionClient = new SubscriptionClient(
  "wss://eu1.prisma.sh/julien-0b8978/souhait/dev",
  {reconnect: true}
);

const client = createClient({
  url: 'https://eu1.prisma.sh/julien-0b8978/souhait/dev',
  fetchOptions: {
    headers: {
      Authorization: `Bearer ${WISHLIST_PRISMA_TOKEN}`
    }
  },
  exchanges: [
    ...defaultExchanges,
    dedupExchange,
    cacheExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: operation => subscriptionClient.request(operation)
    })
  ]
});

export const PrismaProvider: FC = ({children}) => <Provider value={client}>{children}</Provider>;