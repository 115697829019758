import React, {FC, memo, useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faExclamationTriangle, faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import {ReservationFormContext} from "./reservation/ReservationForm/ReservationForm";

export const WishlistFormPrivacy: FC<{ authorNames: string }> = memo(({authorNames}) => (
  <article className="message is-small is-success privmsg">
    <div className="message-body has-text-centered">
      <FontAwesomeIcon icon={faLock}/>&nbsp;&nbsp;
      <strong>Les informations ci-dessous ne seront visibles que par {authorNames}</strong>
    </div>
  </article>
));

export const WishlistFormName: FC<{ defaultName: string, onChange: (state: any) => void }> = ({defaultName, onChange}) => {
  const {invalidFields} = useContext(ReservationFormContext);
  return (
    <div className="field">
      <label className="label">Votre nom et prénom : </label>
      <p className="control is-expanded has-icons-left">
        <input className={"input " + (invalidFields && invalidFields.indexOf("name") > -1 ? " is-danger " : "")}
               type="text"
               name="name" defaultValue={defaultName}
               onChange={onChange} placeholder="Votre nom et prénom"/>
        <span className="icon is-small is-left"><FontAwesomeIcon icon={faUser}/> </span>
      </p>
    </div>
  )
};

export const WishlistFormEmail: FC<{ defaultEmail: string, onChange: (state: any) => void }> = ({defaultEmail, onChange}) => {
  const {invalidFields} = useContext(ReservationFormContext);
  return (
    <>
      <div className="field">
        <label className="label">Votre adresse e-mail :</label>
        <p className="control is-expanded has-icons-left has-icons-right">
          <input className={"input " + (invalidFields && invalidFields.indexOf("email") > -1 ? " is-danger " : "")}
                 type="email"
                 placeholder="Email" defaultValue={defaultEmail}
                 name="email" onChange={onChange}/>
          <span className="icon is-small is-left"><FontAwesomeIcon icon={faEnvelope}/></span>
        </p>
      </div>

      <article className="message is-small is-danger">
        <div className="message-body has-text-centered">
          <FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;&nbsp;
          <strong>Attention, vos e-mails de confirmation seront envoyés à cette adresse</strong>
        </div>
      </article>
    </>
  )
};

export const WishlistFormMessage: FC<{ authorNames: string, onChange: (m: string) => void }> = memo(({authorNames, onChange}) => (
  <div className="field">
    <label className="label">Ecrire un message à {authorNames} (facultatif) :</label>
    <div className="control">
          <textarea
            className="textarea"
            name="message"
            onChange={e => onChange(e.target.value)}
            placeholder="Mon message"/>
    </div>
  </div>
));

export const WishlistFormDisclaimer: FC<{ title: string }> = ({title, children}) => (
  <article className="message is-link">
    <div className="message-body">

      <div className="has-text-centered">
        {title}
      </div>
      <br/>
      {children}
    </div>
  </article>
);