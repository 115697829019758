import React, {FC, useState} from "react";
import ContributionSlider from "./ContributionSlider";
import formatPrice from "../../formatPrice";

const ContributionFormSlider: FC<{ total: number, onChange: (val: number) => void }> = ({total, onChange}) => {
  const defaultValue = total < 10 ? total : Math.round(total / 2);
  const [value, setValue] = useState<number>(defaultValue);
  onChange(value);

  return (
    <div className="field">
      <label className="label has-text-centered">Montant de votre contribution:&nbsp;
        <span className="has-text-primary">{formatPrice(value)}</span>
      </label>
      <div className="control" style={{margin: '0 2rem 2rem'}}>

        <ContributionSlider
          defaultValue={defaultValue}
          max={total}
          onChange={(val: number) => {
            setValue(val);
            onChange(val);
          }}/>

      </div>
    </div>
  );
};

export default ContributionFormSlider
