import React, {FC} from "react";
import {getRemainingPrice, ListItem} from "../../models/ListItem";
import ItemCardSlider from "./ItemCardSlider";
import FadeInDiv from "../FadeInDiv";
import {makeItemUrl} from "./makeItemUrl";
import {ItemCardContainer, ItemCardImage, ItemCardLink, ItemCardMessage, ItemCardTitle, LinkIf} from "./ItemCard";
import ItemCardPrice from "./ContributionItemCard/ItemCardPrice";
import formatPrice from "../formatPrice";
import HeaderRibbon from "./ContributionItemCard/HeaderRibbon";

interface ContributionItemCard {
  item: ListItem
  active?: boolean
  interactable?: boolean
}

const Footer: FC<{ item: ListItem }> = ({item, children}) => {
  const remainingPrice = getRemainingPrice(item);
  return (
    <>
      <ItemCardPrice
        remainingPrice={formatPrice(remainingPrice)}
        totalPrice={formatPrice(item.totalPrice)}
      />

      <footer className="card-footer">
        <div className="card-footer-item" style={{
          padding: "1rem 2rem " + (item.message ? 1 : 2) + "rem 2rem"
        }}>
          <ItemCardSlider item={item} remainingPrice={remainingPrice}/>
        </div>
      </footer>

      {item.message && <ItemCardMessage message={item.message}/>}

      {children}
    </>
  );
};

const ContributionItemCard: FC<ContributionItemCard> = ({item, active = true, interactable = true, children}) => (
  <FadeInDiv>

    <ItemCardContainer active={active} item={item}>

      <LinkIf condition={interactable} href={makeItemUrl(item)}>
        {item.imageUrl && <ItemCardImage imageUrl={item.imageUrl}/>}
        <HeaderRibbon item={item}/>
        <ItemCardTitle title={item.title}/>
      </LinkIf>

      <ItemCardLink linkUrl={item.linkUrl}/>

      <LinkIf condition={interactable} href={makeItemUrl(item)}>
        <Footer item={item}>{children}</Footer>
      </LinkIf>

    </ItemCardContainer>

  </FadeInDiv>
);

export default ContributionItemCard