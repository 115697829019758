import {ListItem} from "./ListItem";
import {Contributor} from "./Contributor";
import {useMutation} from "urql";

type DateTime = string;

export interface Reservation {
  id: string;
  item: ListItem;
  contributor: Contributor;
  message: string;
  isFinalized: boolean;
  createdAt: DateTime
  updatedAt: DateTime
}

interface CreateReservationVariables {
  itemId: string,
  email: string,
  message?: string
}

const createReservationMutation = `
mutation($itemId: ID!, $email: String!, $message: String) {
  createReservation(
    data: {
      item: { connect: { id: $itemId } }
      contributor: { connect: { email: $email } }
      message: $message
      isFinalized: false
    }
  ) {
    id
    contributor {
      id
      email
      name
      phone
    }
  }
}
`;

export const useCreateReservation = () => {
  return useMutation<Reservation, CreateReservationVariables>(createReservationMutation);
};

const confirmReservationMutation = `
mutation($id: ID!) {
  updateReservation(
    data: { isFinalized: true }
    where: { id: $id }
  ) {
    id
  }
}
`;

export const useConfirmReservation = () => {
  return useMutation<Reservation, { id: string }>(confirmReservationMutation);
};

const deleteReservationMutation = `
mutation($id: ID!) {
  deleteReservation(where: { id: $id }) {
    id
  }
}
`;

export const useDeleteReservation = () => {
  return useMutation<Reservation, { id: string }>(deleteReservationMutation);
};