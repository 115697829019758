import {useQuery} from "urql";

const useContributions = (email: string) => {
  const [{data, fetching}] = useQuery({query, variables: {email}});
  return {fetching, ...data}
};

export default useContributions;

const query = `
query($email: String!) {
  contributions(where: { contributor: { email: $email } }, orderBy: createdAt_DESC) {
    id
    amount
    isFinalized
    createdAt
    updatedAt
    contributor {
      email
    }
    item {
      id
      title
      message
      totalPrice
      linkUrl
      imageUrl
      reservationOnly
      list {
        id
      }
      contributions {
        id
        amount
        isFinalized
        contributor {
          email
        }
      }
      reservation {
        id
        isFinalized
      }
    }
  }
  reservations(where: { contributor: { email: $email } }, orderBy: createdAt_DESC) {
    id
    isFinalized
    createdAt
    updatedAt
    item {
      id
      title
      message
      totalPrice
      linkUrl
      imageUrl
      reservationOnly
      list {
        id
      }
      contributions {
        id
        amount
        isFinalized
        contributor {
          email
        }
      }
      reservation {
        id
        isFinalized
      }
    }
  }
}

`;