import React, {FC} from "react";

interface SubmitterButtonProps {
  disabled?: boolean
  loading?: boolean
  fullWidth?: boolean
  outlined?: boolean
  disabledReason?: string
  onClick: () => void
}

const SubmitterButton: FC<SubmitterButtonProps> = ({
                                                     onClick,
                                                     outlined = false,
                                                     fullWidth = false,
                                                     loading = false,
                                                     disabled = false,
                                                     disabledReason = "Vous devez accepter les conditions",
                                                     children
                                                   }) => (
  <button
    disabled={disabled}
    className={"button is-primary is-rounded " + (fullWidth ? " is-fullwidth " : "") + (outlined ? " is-outlined " : "") + (loading ? " is-loading " : "")}
    {...(!disabled && ({onClick}))}
    {...(disabled && {title: disabledReason})}>
    {children}
  </button>
);

export default SubmitterButton