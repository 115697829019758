import {resolve} from "path"
import {config} from "dotenv"

config({
  path: resolve(__dirname, "../.env"),
  debug: true
});

export const WISHLIST_BASE_URL_RELATIVE = process.env.REACT_APP_WISHLIST_BASE_URL_RELATIVE;
export const WISHLIST_BASE_URL_ABSOLUTE = process.env.REACT_APP_WISHLIST_BASE_URL_ABSOLUTE;
export const WISHLIST_PRISMA_TOKEN = process.env.REACT_APP_WISHLIST_PRISMA_TOKEN;
export const WISHLIST_PRISMA_ID = process.env.REACT_APP_WISHLIST_PRISMA_ID;
export const EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;
export const EMAILS_REPLY_TO = process.env.REACT_APP_EMAILS_REPLY_TO;
export const OPEN_PAYPAL = process.env.REACT_APP_OPEN_PAYPAL === 'true';
export const SEND_EMAILS = process.env.REACT_APP_SEND_EMAILS === 'true';
export const RELEASE_DATE = "2020-02-28 20:00";
export const ENABLE_CANARY = process.env.REACT_APP_ENABLE_CANARY === 'true'