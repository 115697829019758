import React, {FC, useContext} from "react";
import {ContributorContext} from "../../contexts/ContributorContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGift} from "@fortawesome/free-solid-svg-icons";
import HeaderDropdown from "./HeaderDropdown/HeaderDropdown";
import LoginDropdown from "./HeaderDropdown/LoginDropdown/LoginDropdown";

const HeaderContributor: FC = () => {
  const {contributor, isLogged} = useContext(ContributorContext);
  return isLogged
    ? (
      <>
        <div className="navbar-item">{contributor.name}</div>
        <div className="navbar-item">
          <div className="buttons"><HeaderDropdown contributor={contributor}/></div>
        </div>
      </>
    ) : (
      <div className="navbar-item">
        <div className="buttons"><LoginDropdown/></div>
      </div>
    )
};

const HeaderNavbar: FC = () => (
  <div className="hero-head">
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="container">


        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <strong><FontAwesomeIcon icon={faGift}/> &nbsp;faites un souha.it !</strong>
          </a>
        </div>

        <div className="navbar-end">
          <HeaderContributor/>
        </div>

      </div>
    </nav>
  </div>
);

export default HeaderNavbar