import React, {FC} from "react";

const NotFound: FC = () => {
  return (
    <div style={{
      position: "absolute",
      display: "flex",
      height: "100%",
      width: "100%",
      top: 0,
      left: 0,
      backgroundImage: "linear-gradient(141deg, #004f55 0%, #006788 71%, #005fa2 100%)"
    }}>
      <div style={{margin: "auto", color: "white"}}><h1>Page introuvable</h1></div>
    </div>
  )
};

export default NotFound;
