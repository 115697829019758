import React, {createContext, FC, useContext, useState} from "react";
import {Contribution} from "../../../models/Contribution";
import {ContributorContext} from "../../../contexts/ContributorContext";
import {
  WishlistFormDisclaimer,
  WishlistFormEmail,
  WishlistFormMessage,
  WishlistFormName,
  WishlistFormPrivacy
} from "../../WishlistForm";
import ContributionFormSlider from "./ContributionFormSlider";
import ContributionFormSubmit from "./ContributionFormSubmit";
import {Contributor} from "../../../models/Contributor";
import {ContributionContext} from "../../../contexts/ContributionContext";
import {WishlistContext} from "../../../contexts/WishlistContext";
import {getRemainingPrice} from "../../../models/ListItem";

export type ContributionFormField = "name" | "email"

interface IContributionFormContext {
  invalidFields: ContributionFormField[]
  setInvalidFields: (s: ContributionFormField[]) => void
  contributor: Contributor
  message: string
  contributionValue: number
  setContributionValue: (n: number) => void
}

export const ContributionFormContext = createContext<IContributionFormContext>({} as IContributionFormContext);

const ContributionForm: FC = () => {
  const [message, setMessage] = useState<string>('');
  const {contributor, setContributor} = useContext(ContributorContext);
  const {setContribution, item} = useContext(ContributionContext);
  const {authorNames} = useContext(WishlistContext);

  const updateContributor = (state: any) => {
    setContributor({
      ...contributor,
      [state.target.name]: state.target.value
    });
  };

  const [invalidFields, setInvalidFields] = useState<ContributionFormField[]>([]);
  const [contributionValue, setContributionValue] = useState<number>(0);

  const context = {
    contributionValue,
    setContributionValue,
    contributor,
    invalidFields,
    message,
    setInvalidFields
  };

  if (!item.id) return null;
  const remainingPrice = getRemainingPrice(item);

  return (
    <ContributionFormContext.Provider value={context}>

      <WishlistFormPrivacy authorNames={authorNames}/>

      <ContributionFormSlider
        total={remainingPrice}
        onChange={setContributionValue}/>

      <WishlistFormName
        defaultName={contributor.name}
        onChange={updateContributor}
      />

      <WishlistFormEmail
        defaultEmail={contributor.email}
        onChange={updateContributor}
      />

      <WishlistFormMessage
        authorNames={authorNames}
        onChange={setMessage}
      />

      <WishlistFormDisclaimer title="Vous êtes sur le point de contribuer à l'achat de cet objet.">
        Après avoir rempli ce formulaire, vous serez redirigé vers le site de Paypal pour effectuer votre paiement
        sécurisé (indiqué comme "Faire un don") avec Paypal ou par carte.
      </WishlistFormDisclaimer>

      <ContributionFormSubmit
        itemId={item.id}
        onSubmitted={(contribution: Contribution) => {
          setContribution(contribution);
          setContributor(contribution.contributor);
        }}
      />

    </ContributionFormContext.Provider>
  );
};

export default ContributionForm