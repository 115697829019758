import React, {FC, useEffect} from "react";
import localforage from 'localforage'
import {useNavigation} from "react-navi";
import Loading from "./Loading";

const Deconnexion: FC = () => {
  const navigation = useNavigation();

  useEffect(() => {
    localforage.setItem('contributor', null);
    setTimeout(() => {
      navigation.navigate('/lia-julien/')
    }, 2000);
  }, [navigation]);

  return <Loading text="Vous êtes déconnecté. Redirection vers l'accueil..."/>
};

export default Deconnexion;
