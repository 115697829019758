import React, {FC, useContext, useState} from "react";
import {useNavigation} from "react-navi";
import {ListItem} from "../models/ListItem";
import {TermsAcceptanceContext} from "../components/TermsAcceptanceProvider";

interface ModalContext {
  item: ListItem
  setItem: (i: ListItem | ((item: ListItem) => any)) => ListItem | void
  isModalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

export const ModalContext = React.createContext<ModalContext>({} as ModalContext);

export const ModalContextProvider: FC = ({children}) => {
  const {denyTerms} = useContext(TermsAcceptanceContext);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [item, setItem] = useState<ListItem>({} as ListItem);
  const navigation = useNavigation();

  const context = {
    item,
    setItem,
    isModalOpen,
    openModal: () => setModalOpen(true),
    closeModal: () => {
      navigation.navigate('/lia-julien/').then(() => {
        denyTerms();
        setModalOpen(false);
      });
    }
  };

  return <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
};