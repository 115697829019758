import React, {FC, useContext} from "react";
import {Reservation} from "../../../models/Reservation";
import {makeItemUrl} from "../../ItemCard/makeItemUrl";
import {ModalContext} from "../../../contexts/ModalContext";
import {Link} from "react-navi";
import {DropdownSubtitle} from "./HeaderDropdown";

export const HeaderReservations = ({reservations}: { reservations: Reservation[] }) => {
  if (!reservations.length) {
    return <div className="dropdown-item">Vous n'avez pas encore réservé.</div>
  }
  return <>
    {reservations.map((r: Reservation) => (
      r.isFinalized
        ? <FinalizedReservation key={r.id} reservation={r}/>
        : <ReservationNeedsConfirmation key={r.id} reservation={r}/>
    ))}
  </>
};

export const FinalizedReservation: FC<{ reservation: Reservation }> = ({reservation}) => (
  <div className="dropdown-item">
    <div className="columns is-mobile">
      <div className="column is-3">
        <img src={reservation.item.imageUrl || ""} alt={reservation.item.title}/>
      </div>
      <div className="column is-9">
        <DropdownSubtitle date={reservation.createdAt}/>
        Vous avez réservé <strong>{reservation.item.title}</strong>
      </div>
    </div>
  </div>
);

export const ReservationNeedsConfirmation: FC<{ reservation: Reservation }> = ({reservation}) => {
  const {openModal} = useContext(ModalContext);
  return (
    <Link href={makeItemUrl(reservation.item)} onClick={openModal}>
      <div className="dropdown-item">
        <div className="columns is-mobile">
          <div className="column is-3">
            <img src={reservation.item.imageUrl || ""} alt={reservation.item.title}/>
          </div>
          <div className="column is-9">
            <DropdownSubtitle date={reservation.createdAt}/>
            Vous avez réservé <strong>{reservation.item.title}</strong>
            &nbsp;&nbsp;<span className="has-text-link">Confirmer</span>
          </div>
        </div>
      </div>
    </Link>
  );
};
