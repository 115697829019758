import React, {FC, memo} from "react";
import {ListItem} from "../../../models/ListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import useReservationStatus from "../../reservation/useReservationStatus";

const ConfirmedRibbon = memo(() => (
  <div className="ribbon is-primary is-size-7" title="Vous avez réservé ce cadeau">
    <FontAwesomeIcon icon={faCheck}/>
  </div>
));

const ContributedRibbon = memo(() => (
  <div className="ribbon is-warning is-size-7" title="Vous devez confirmer votre réservation">
    <FontAwesomeIcon icon={faExclamationTriangle}/>
  </div>
));

const HeaderRibbon: FC<{ item: ListItem }> = ({item}) => {
  switch (useReservationStatus(item)) {
    case "STARTED BY CURRENT USER":
      return <ContributedRibbon/>;
    case "FINALIZED BY CURRENT USER":
      return <ConfirmedRibbon/>
    case "NOT RESERVED":
    case "STARTED BY SOMEONE":
    case "FINALIZED BY SOMEONE":
      return null;
  }
}

export default HeaderRibbon