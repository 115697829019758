import React, {FC} from "react";
import {getWishlist, Wishlist} from "../models/Wishlist";
import {useQuery} from "urql";
import Loading from "../screens/Loading";
import {WISHLIST_PRISMA_ID} from "../constants";

export const WishlistContext = React.createContext<Wishlist>({} as Wishlist);

export const WishlistProvider: FC = ({children}) => {
  const [{data, fetching, error}] = useQuery({
    query: getWishlist,
    variables: {id: WISHLIST_PRISMA_ID}
  });

  if (fetching) {
    return <Loading/>;
  } else if (error) {
    return <Loading text="Erreur :("/>;
  }

  if (null === data || null === data.wishlist) {
    return <Loading text="La liste n'a pas été trouvée."/>;
  }

  return (
    <WishlistContext.Provider value={data.wishlist}>
      {children}
    </WishlistContext.Provider>
  )
};
