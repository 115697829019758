export interface Wishlist {
  id: string
  message: string
  subtitle: string
  authorNames: string
  title: string
}

export const getWishlist = `
query GetWishlist($id: ID!) {
  wishlist(where: { id: $id }) {
    id
    message
    subtitle
    authorNames
    title
  }
}
`;

export const getWishlistItems = `
query GetWishlistItems($id: ID!) {
  wishlist(where: { id: $id }) {
    items {
      totalPrice
      reservationOnly
      reservation {
        isFinalized
      }
      contributions {
        amount
      }
    }
  }
}
`;
