import {Reservation} from "../../../models/Reservation";
import React, {FC, useContext} from "react";
import {TermsAcceptanceContext} from "../../TermsAcceptanceProvider";
import ReservationSubmitter from "./ReservationSubmitter";

interface IReservationFormSubmit {
  itemId: string
  terms: string
  onSubmitted: (reservation: Reservation) => void
}

const ReservationFormSubmit: FC<IReservationFormSubmit> = ({itemId, terms, onSubmitted}) => {
  const {hasAcceptedTerms, denyTerms, makeInput} = useContext(TermsAcceptanceContext);
  return (
    <div className="field is-grouped">
      <div className="control is-expanded">

        <div className="field">
          {makeInput(itemId, terms)}
        </div>

        <ReservationSubmitter
          disabled={!hasAcceptedTerms}
          onSubmitted={(reservation: Reservation) => {
            denyTerms();
            onSubmitted(reservation);
          }}
        />

      </div>
    </div>
  )
};

export default ReservationFormSubmit