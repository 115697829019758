import React, {FC, useContext} from "react";
import {useQuery} from "urql";
import Slider from "rc-slider";
import {ListItem} from "../models/ListItem";
import {getWishlistItems} from "../models/Wishlist";
import {WishlistContext} from "../contexts/WishlistContext";
import formatPrice from "./formatPrice";

const sliderStyle = {
  trackStyle: {
    backgroundColor: '#006788',
    transition: 'ease-out 1s all'
  },
  dotStyle: {
    display: "none",
  }
};

const getListCompletion = (items: ListItem[]) => ({
  currentPrice: (
    items.reduce((res, i: ListItem) =>
      i.reservationOnly
        ? res + (i.reservation ? i.totalPrice : 0)
        : res + i.contributions.reduce((r, c) => r + c.amount, 0), 0)
  ),
  totalPrice: items.reduce((res, i: ListItem) => res + i.totalPrice, 0)
});

const WishlistCompletion: FC = () => {
  const list = useContext(WishlistContext);
  const [{data, fetching}] = useQuery({query: getWishlistItems, variables: {id: list.id}});

  if (fetching) return <>Chargement...</>;

  const {currentPrice, totalPrice} = getListCompletion(data.wishlist.items);

  const marks = {
    [currentPrice]: <strong>{formatPrice(currentPrice)}</strong>,
    [totalPrice]: <strong>{formatPrice(totalPrice)}</strong>
  };

  return (
    <Slider min={0} marks={marks} included={true}
            value={currentPrice}
            max={totalPrice}
            trackStyle={sliderStyle.trackStyle}
            dotStyle={sliderStyle.dotStyle}
            handleStyle={sliderStyle.dotStyle}/>

  )
};

export default WishlistCompletion