import React, {FC, memo} from "react";
import {ListItem} from "../../../models/ListItem";
import useContributionStatus from "../../contribution/useContributionStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

const ConfirmedRibbon = memo(() => (
  <div className="ribbon is-primary is-size-7" title="Vous avez contribué à ce cadeau">
    <FontAwesomeIcon icon={faCheck}/>
  </div>
));

const ContributedRibbon = memo(() => (
  <div className="ribbon is-warning is-size-7" title="Vous devez confirmer votre contribution">
    <FontAwesomeIcon icon={faExclamationTriangle}/>
  </div>
));

const HeaderRibbon: FC<{ item: ListItem }> = ({item}) => {
  switch (useContributionStatus(item)) {
    case "STARTED BY CURRENT USER":
      return <ContributedRibbon/>;
    case "FINALIZED BY CURRENT USER":
      return <ConfirmedRibbon/>
    case "NO CONTRIBUTIONS":
    case "FULLY CONTRIBUTED":
      return null;
  }
}

export default HeaderRibbon