import React, {FC, useContext} from "react";
import {WishlistContext} from "../contexts/WishlistContext";
import {HeroBottomBar} from "./HeroBottomBar";
import {AppGrid} from "./AppGrid";
import {ContributorContextProvider} from "../contexts/ContributorContext";
import {OrderByContextProvider} from "../contexts/OrderByContext";
import HeaderNavbar from "./Header/HeaderNavbar";
import HeroBody from "./Header/HeroBody";
import {ModalContextProvider} from "../contexts/ModalContext";
import TermsAcceptanceProvider from "./TermsAcceptanceProvider";
import {ReservationContextProvider} from "../contexts/ReservationContext";
import {ContributionContextProvider} from "../contexts/ContributionContext";
import Loading from "../screens/Loading";
import HeroFoot from "./Header/HeroFoot";
import {ENABLE_CANARY} from "../constants";
import {AppLinkbox} from "./AppLinkbox";

const WishlistComponent: FC = () => {
  const wishlist = useContext(WishlistContext);

  if (null === wishlist) {
    return <Loading text="La liste n'a pas été trouvée."/>
  }

  const canary = ENABLE_CANARY === 'true';

  return (
    <TermsAcceptanceProvider>
      <ModalContextProvider>
        <ReservationContextProvider>
          <ContributorContextProvider>
            <ContributionContextProvider>
              <section className="hero is-primary is-small is-bold">
                <HeaderNavbar/>
                <HeroBody/>
                {canary && <HeroFoot/>}
              </section>

              {canary && <AppLinkbox/>}
              <OrderByContextProvider>
                <HeroBottomBar/>
                <AppGrid/>
              </OrderByContextProvider>
            </ContributionContextProvider>
          </ContributorContextProvider>
        </ReservationContextProvider>
      </ModalContextProvider>
    </TermsAcceptanceProvider>
  )
};

export default WishlistComponent