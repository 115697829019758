import React, {FC, useEffect} from "react";
import FlipDown from './FlipDown/FlipDown'
import './FlipDown/flipdown.css'
import {RELEASE_DATE} from "../constants";

const CountDown: FC = () => {
  useEffect(() => {
    const dateTime = +new Date(RELEASE_DATE);
    const timestamp = Math.floor(dateTime / 1000);
    new FlipDown(timestamp, 'flipdown', { theme: 'light' }).start().ifEnded(() => {
      console.log('The countdown has ended!');
    });
  })
  return (
    <div id="flipdown" className="flipdown"/>
  )
}

export default CountDown