import React, {memo} from "react";
import Slider from "rc-slider";
import formatPrice from "../../formatPrice";

const makeContributionMarks = (max: number) => {
  let marks = [];
  let mult = max > 100 ? 100 : max > 10 ? 10 : 5;
  for (let i = 1; i < max / mult; i++) {
    marks.push(i * mult);
  }
  return ({
    ...marks.reduce((res, next) => ({...res, [next * 100]: formatPrice(next)}), {}),
    [max * 100]: <strong>{max < marks[marks.length - 1] + 10 ? "" : formatPrice(max)}</strong>
  });
};

const sliderStyle = {
  trackStyle: {
    backgroundColor: ' #006788'
  },
  handleStyle: {
    borderColor: ' #006788'
  },
  activeDotStyle: {
    borderColor: ' #006788'
  }
};

interface ContributionSlider {
  max: number,
  defaultValue: number,
  onChange: (val: number) => void
}

const ContributionSlider: React.FC<ContributionSlider> = memo(({max, defaultValue, onChange}) => (
  <Slider min={300} max={max * 100}
          marks={makeContributionMarks(max)}
          included={true}
          onChange={(v: number) => onChange(v / 100)}
          step={100}
          defaultValue={defaultValue * 100}
          {...sliderStyle} />
));

export default ContributionSlider