import React, {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {ContributionContext} from "../../../contexts/ContributionContext";
import {useConfirmContribution} from "../../../models/Contribution";
import SubmitterButton from "../ContributionSubmitter/SubmitterButton";

const ConfirmButton: React.FC<{ contributionId: string }> = ({contributionId}) => {
  const [{fetching, error}, confirmMutation] = useConfirmContribution();
  const {confirm: confirmContext} = useContext(ContributionContext);

  if (error) {
    return <>Erreur :(</>;
  }

  const onClick = () => {
    confirmMutation({id: contributionId}).then(confirmContext);
  };

  return (
    <SubmitterButton onClick={onClick} loading={fetching}>
      <span className="icon"><FontAwesomeIcon icon={faCheck}/></span>
      <span>Oui</span>
    </SubmitterButton>
  )
};

export default ConfirmButton