import {Contribution} from "../../../models/Contribution";
import React, {FC, useContext} from "react";
import {TermsAcceptanceContext} from "../../TermsAcceptanceProvider";
import ContributionSubmitter from "../ContributionSubmitter/ContributionSubmitter";

interface IContributionSubmit {
  itemId: string
  onSubmitted: (contribution: Contribution) => void
}

const ContributionFormSubmit: FC<IContributionSubmit> = ({itemId, onSubmitted}) => {
  const {hasAcceptedTerms, denyTerms, makeInput} = useContext(TermsAcceptanceContext);

  return (
    <div className="field is-grouped">
      <div className="control is-expanded">

        <div className="field">
          {makeInput(itemId)}
        </div>

        <ContributionSubmitter
          disabled={!hasAcceptedTerms}
          onSubmitted={(contribution: Contribution) => {
            denyTerms();
            onSubmitted(contribution)
          }}
        />

      </div>
    </div>
  )
};

export default ContributionFormSubmit
