import * as React from "react";
import {FC, useState} from "react";
import localforage from 'localforage'

export interface LoginAttempt {
  email: string
  when: Date
}

interface ILoginContext {
  setLoginAttempts: (a: LoginAttempt[]) => void
  sendLoginLink: (email: string, onSucceed: () => void, onFail: () => void) => void
}

const updateLoginAttempts = (data: any) => {
  localforage.setItem('login-attempts', data);
};

export const LoginContext = React.createContext<ILoginContext>({} as ILoginContext);

export const LoginProvider: FC = ({children}) => {
  const [loginAttempts, setLoginAttempts] = useState<LoginAttempt[]>([]);
  const getAttemptsByEmail = (email: string) => {
    return loginAttempts
      .filter(a => a.email === email)
      .sort((a, b) => (+b.when) - (+a.when)) as LoginAttempt[]
  };
  const getSecondsSinceAttempt = (a: LoginAttempt) => {
    return (+new Date() - +a.when) / 1000;
  };
  const createNewAttempt = (email: string) => {
    const attempt = {email, when: new Date()} as LoginAttempt;
    setLoginAttempts(loginAttempts.concat([attempt]));
    updateLoginAttempts(loginAttempts);
  };
  const sendLoginLink = (email: string, onSucceed: () => void, onFail: () => void) => {
    if (!email) return;
    const previousAttemps = getAttemptsByEmail(email);

    if (previousAttemps.length) {
      if (previousAttemps.length > 3) {
        return onFail();
      }
      const secondsSince = getSecondsSinceAttempt(previousAttemps[0]);
      if (secondsSince < 60) {
        return onFail();
      }
    }

    // add attempt to session
    createNewAttempt(email);
    return onSucceed();
  };

  const context = {
    setLoginAttempts,
    sendLoginLink
  };
  return (
    <LoginContext.Provider value={context}>
      {children}
    </LoginContext.Provider>
  )
};
