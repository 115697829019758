import {Contributor} from "./models/Contributor";

export const removeSpaces = (val: string) => val.replace(/\s/gis, '')

export const isNotEmpty = (val: string) => removeSpaces(val).length > 0

const validateContributor = (contributor: Contributor) =>
  isNotEmpty(contributor.email)
  && isNotEmpty(contributor.name)

export default validateContributor;
