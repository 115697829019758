import {CombinedError, OperationResult} from "urql";
import React, {useContext} from "react";
import {Reservation, useCreateReservation} from "../../../models/Reservation";
import {ReservationFormContext} from "./ReservationForm";
import {sendReservationEmail} from "./utils";
import {SEND_EMAILS} from "../../../constants";
import {useUpsertContributor} from "../../../models/Contributor";
import {ReservationContext} from "../../../contexts/ReservationContext";
import SubmitterButton from "../../contribution/ContributionSubmitter/SubmitterButton";
import validateContributor, {removeSpaces} from "../../../validateContributor";

interface ReservationSubmitterProps {
  disabled: boolean
  onSubmitted: (reservation: Reservation) => void
}

const useReservationSubmit: (onSubmitted: (r: Reservation) => void) => [{ fetching: boolean; error?: CombinedError }, () => void] = (onSubmitted: (r: Reservation) => void) => {
  const {item} = useContext(ReservationContext);
  const {message, contributor} = useContext(ReservationFormContext);
  const [{fetching: fetchingContributor, error: errorContributor}, upsertContributor] = useUpsertContributor();
  const [{fetching: fetchingReservation, error: errorReservation}, createReservation] = useCreateReservation();

  const submitReservation = () => {
    upsertContributor(contributor)
      .then((res) => {
        if (res.error) return;
        createReservation({
          itemId: item.id,
          email: removeSpaces(contributor.email),
          message
        })
          .then((res: OperationResult) => {
            SEND_EMAILS && sendReservationEmail(contributor.name, contributor.email, item);
            onSubmitted(res.data.createReservation);
          });
      })
  };

  return [{
    fetching: fetchingReservation || fetchingContributor,
    error: errorReservation || errorContributor
  }, submitReservation];
};

const ReservationSubmitter: React.FC<ReservationSubmitterProps> = ({disabled, onSubmitted}) => {
  const {contributor} = useContext(ReservationFormContext);
  const [{fetching, error}, submitReservation] = useReservationSubmit(onSubmitted);

  if (error) return <>Erreur :(</>;

  return (
    <SubmitterButton
      outlined
      fullWidth
      loading={fetching}
      disabled={disabled}
      disabledReason={"Vous devez accepter les conditions"}
      onClick={() => {
        if (!validateContributor(contributor)) {
          alert('Merci de renseigner votre nom et votre e-mail.');
          return;
        }
        submitReservation();
      }}
    >
      Confirmer ma réservation
    </SubmitterButton>
  )
};

export default ReservationSubmitter