import React from "react";

export const AppLinkbox: React.FC = () => (
  <section className="hero is-primary is-small is-bold">
    <div className="hero-body">
      <div className="container">
        <div className="card">
          <div className="card-content">
            <div className="content">
              <div className="control has-icons-left has-icons-right">
                <input className="input is-large" type="search"/>
                <span className="icon is-medium is-left"><i className="fa fa-search"/></span>
                <span className="icon is-medium is-right"><i className="fa fa-empire"/></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
