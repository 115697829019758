import React, {FC} from "react";
import localforage from 'localforage'
import {useCurrentRoute, useNavigation} from "react-navi";
import Loading from "./Loading";
import {useQuery} from "urql";

const query = `
query($token: String!) {
  contributorsConnection(where: { magicLinks_some: { token: $token } }) {
    edges {
      node {
        id
        name
        email
        phone
        createdAt
        updatedAt
      }
    }
  }
}
`;

const Connexion: FC = () => {
  const navigation = useNavigation();

  const route = useCurrentRoute();
  const routeToken = route.lastChunk.request && route.lastChunk.request.params.token;
  const [{data, fetching}] = useQuery({query, variables: {token: routeToken}});
  if (fetching) return <Loading/>;

  if (data.contributorsConnection.edges.length > 0) {
    const contributorFromToken = data.contributorsConnection.edges[0].node;
    localforage.setItem('contributor', contributorFromToken);
  }

  setTimeout(() => {
    navigation.navigate('/lia-julien/')
  }, 2000);

  return <Loading text="Vous êtes connecté. Redirection vers l'accueil..."/>
};

export default Connexion;
