import React, {FC, useState} from "react";
import 'moment/locale/fr';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import LoginButton from "./LoginButton";
import {LoginProvider} from "./LoginContext";
import {useMedia} from "react-use";

const LoginDropdownContents: FC = () => {
  const [email, setEmail] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  if (submitted && !notFound) {
    return <>Pour vous connecter, veuillez cliquer sur le lien qui vient de vous être envoyé par e-mail.</>
  }

  return (
    <>
      <p>Vous avez déjà contribué ou réservé ? Entrez votre adresse e-mail pour vous connecter.</p>
      <br/>
      {notFound && <span className="has-text-dark">Adresse non trouvée.</span>}
      {!submitted && (
        <div className="field is-grouped">
          <p className="control is-expanded has-icons-left">

          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faEnvelope}/>
          </span>
            <input className="input" type="text"
                   onChange={e => {
                     setNotFound(false);
                     setEmail(e.target.value);
                   }}
                   placeholder="Votre adresse email"/>
          </p>
          <p className="control">
            {!submitted && (
              <LoginButton
                email={email}
                onClick={() => setSubmitted(true)}
                onEmailNotFound={() => setNotFound(true)}/>
            )}
          </p>
        </div>
      )}
    </>
  )
}

const LoginDropdown: FC = () => {
  const isWide = useMedia('(min-width: 780px)');

  return (
    <div className={"dropdown is-hoverable" + (isWide ? " is-right " : "")}>

      <div className="dropdown-trigger">
        <button className="button is-primary"
                aria-haspopup="true"
                aria-controls="dropdown-menu2">
          <span>Se connecter</span>
          <span className="icon is-small"><FontAwesomeIcon icon={faAngleDown}/></span>
        </button>
      </div>

      <div className="dropdown-menu" id="dropdown-menu2" role="menu">
        <div className="dropdown-content">

          <div className="dropdown-item">

            <LoginProvider>
              <LoginDropdownContents/>
            </LoginProvider>

          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginDropdown