import React, {FC} from "react";
import {WishlistProvider} from "../contexts/WishlistContext";
import WishlistComponent from "../components/WishlistComponent";
import {OrderByContextProvider} from "../contexts/OrderByContext";

const Wishlist: FC = () => (
  <OrderByContextProvider>
    <WishlistProvider>
      <WishlistComponent/>
    </WishlistProvider>
  </OrderByContextProvider>
);

export default Wishlist;
