import React, {FC, useContext, useEffect, useState} from "react";
import {ListItem} from "../models/ListItem";
import {ContributorContext} from "./ContributorContext";
import {Contribution} from "../models/Contribution";
import {ModalContext} from "./ModalContext";
import useContributionStatus, {getContributorContributions} from "../components/contribution/useContributionStatus";

interface ContributionContext {
  item: ListItem
  contribution: Contribution | null
  hasStarted: boolean
  hasFinalized: boolean
  remove: () => void
  confirm: () => void
  reset: () => void
  setContribution: (contribution: Contribution) => void
}

export const ContributionContext = React.createContext<ContributionContext>({} as ContributionContext);

export const ContributionContextProvider: FC = ({children}) => {
  const {item, setItem} = useContext(ModalContext);

  const status = useContributionStatus(item);

  const [hasStarted, setStarted] = useState<boolean>(status === "STARTED BY CURRENT USER");
  const [hasFinalized, setFinalized] = useState<boolean>(status === "FINALIZED BY CURRENT USER");
  const [contribution, setContribution] = useState<Contribution | null>(null);

  const {contributor, setContributor} = useContext(ContributorContext);

  useEffect(() => {
    const contributions = getContributorContributions(item, contributor.email);
    contributions.pending.length && setContribution(contributions.pending[0]);
    setFinalized(status === "FINALIZED BY CURRENT USER");
    setStarted(status === "STARTED BY CURRENT USER");
  }, [status, contributor.email, item]);

  const context = {
    item,
    contribution,
    hasStarted,
    hasFinalized,
    setContribution: (contribution: Contribution) => {
      setContribution(contribution);
      setItem(item => ({
        ...item,
        contributions: (item.contributions || []).concat(contribution)
      }));
      setContributor({
        ...contributor,
        contributions: (contributor.contributions || [])
          .filter((contrib: Contribution) => contrib.id !== contribution.id)
          .concat([contribution])
      });
      setStarted(true);
      setFinalized(false);
      console.log('set')
    },
    remove: () => {
      contribution && setItem(item => ({
        ...item,
        contributions: item.contributions.filter(c => c.id !== contribution.id)
      }));
      setContribution(null);
      setStarted(false);
      setFinalized(false);
      console.log('remove')
    },
    confirm: () => {
      setContribution((prevState: Contribution | null) => prevState && ({
        ...prevState,
        isFinalized: true
      }));
      contribution && setItem(item => ({
        ...item,
        contributions: (item.contributions || [])
          .concat({...contribution, isFinalized: true})
      }));
      setStarted(true)
      setFinalized(true)
      console.log('confirm')
    },
    reset: () => {
      setContribution(null);
      setStarted(false);
      setFinalized(false);
      console.log('reset')
    },
  };

  return (
    <ContributionContext.Provider value={context}>{children}</ContributionContext.Provider>
  )
};