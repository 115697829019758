import React, {useContext} from "react";
import {ListItem} from "../../models/ListItem";
import ContributionItemCard from "../ItemCard/ContributionItemCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGift, faLock} from "@fortawesome/free-solid-svg-icons";
import useContributionStatus from "./useContributionStatus";
import {ContributorContext} from "../../contexts/ContributorContext";

const UnlockedItem: React.FC<{ item: ListItem, buttonText: string }> = ({item, buttonText}) => (
  <ContributionItemCard item={item} active={true}>
    <footer className="card-footer has-text-centered" style={{padding: "1rem"}}>
      <button className="button is-primary is-outlined is-fullwidth">
        <span className="icon is-small"><FontAwesomeIcon icon={faGift}/></span>
        <span>{buttonText}</span>
      </button>
    </footer>
  </ContributionItemCard>
);

const LockedItem: React.FC<{ item: ListItem, reason: string }> = ({item, reason}) => {
  const {contributor} = useContext(ContributorContext);
  return (
    <>
    <ContributionItemCard item={item} active={false} interactable={false}>
      <footer className="card-footer has-text-centered">
        <div className="card-footer-item">
          <p className="is-primary is-size-7">
          </p>
        </div>
      </footer>
      <footer className="card-footer has-text-centered">
        <div className="card-footer-item">
          <p className="is-primary is-size-7">
            <FontAwesomeIcon icon={faLock}/> &nbsp;&nbsp;{reason}
          </p>
        </div>
      </footer>
    </ContributionItemCard>
      {contributor.email === 'lia.spaccesi@gmail.com' && (<pre>{JSON.stringify(item.contributions, null, 2)}</pre>)}
    </>
  );
};

const ContributionGridItem: React.FC<{ item: ListItem }> = ({item}) => {
  switch (useContributionStatus(item)) {
    case "NO CONTRIBUTIONS":
      return <UnlockedItem item={item} buttonText="Contribuer"/>
    case "STARTED BY CURRENT USER":
      return <UnlockedItem item={item} buttonText="Confirmer ma contribution"/>
    case "FINALIZED BY CURRENT USER":
      return <LockedItem item={item} reason="Vous avez déjà contribué à cet objet"/>
    case "FULLY CONTRIBUTED":
      return <LockedItem item={item} reason="Objectif atteint ! Contributions closes"/>
  }
};

export default ContributionGridItem
