import React, {FC, memo} from "react";
import {getRemainingPrice, ListItem} from "../../models/ListItem";
import FadeInDiv from "../FadeInDiv";
import {makeItemUrl} from "./makeItemUrl";
import {ItemCardContainer, ItemCardImage, ItemCardLink, ItemCardMessage, ItemCardTitle, LinkIf} from "./ItemCard";
import formatPrice from "../formatPrice";
import HeaderRibbon from "./ReservationItemCard/HeaderRibbon";

interface ReservationItemCard {
  item: ListItem
  active?: boolean
  interactable?: boolean
}

export const ItemCardPrice: FC<{ remainingPrice: string }> = memo(({remainingPrice}) => (
  <footer className="card-footer">
    <div className="card-footer-item">
      <nav className="level">
        <div className="level-item card-footer-item has-text-centered">
          <div>
            <p className="heading has-text-weight-bold">Prix indicatif</p>
            <p className="subtitle has-text-primary has-text-weight-bold is-6">{remainingPrice}</p>
          </div>
        </div>
      </nav>
    </div>
  </footer>
));

export const EmailReservationItemCard: FC<{ item: ListItem }> = memo(({item}) => (
  <>
    <a href={makeItemUrl(item, true)}>
      {item.imageUrl && <div><img alt={item.title} src={item.imageUrl} width="100%"/></div>}
      <br/>
      <div>{item.title}</div>
    </a>
    <br/>
    Prix indicatif: {formatPrice(getRemainingPrice(item))}
    <br/>
    Message:
    <br/>
    <pre>{item.message}</pre>
  </>
));

const ReservationItemCard: FC<ReservationItemCard> = memo(({item, active = true, interactable = true, children}) => (
  <FadeInDiv>

    <ItemCardContainer active={active} item={item}>

      <HeaderRibbon item={item}/>

      <LinkIf condition={interactable} href={makeItemUrl(item)}>
        {item.imageUrl && <ItemCardImage imageUrl={item.imageUrl}/>}
        <ItemCardTitle title={item.title}/>
      </LinkIf>

      <ItemCardLink linkUrl={item.linkUrl}/>

      <LinkIf condition={interactable} href={makeItemUrl(item)}>

        <ItemCardPrice remainingPrice={formatPrice(getRemainingPrice(item))}/>
        {item.message && <ItemCardMessage message={item.message}/>}

        {children}

      </LinkIf>

    </ItemCardContainer>

  </FadeInDiv>
));

export default ReservationItemCard
