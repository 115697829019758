import React, {FC, useContext, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {ModalContext} from "../contexts/ModalContext";


const ThankYouMessage: FC<{ onClose?: () => void }> = ({onClose}) => {
  const {closeModal} = useContext(ModalContext)

  useEffect(() => {
      let timer1 = setTimeout(() => {
        onClose && onClose();
        closeModal();
      }, 5000)

      // this will clear Timeout when component unmont like in willComponentUnmount
      return () => {
        clearTimeout(timer1)
      }
    },
    [onClose, closeModal] //useEffect will run only one time
    //if you pass a value to array, like this [data] than clearTimeout will run every time this value changes (useEffect re-run)
  )
  const hashes = [
    'VIPdgcooFJHtC',
    'DqY8dWBiMus24',
    '69FmYZBku9m81vhGH3',
    'osjgQPWRx3cac',
    'XbxZ41fWLeRECPsGIJ'
  ];
  const hash = hashes.sort(() => .5 - Math.random())[0]
  return (
    <div className="field has-text-centered">
      <p style={{marginBottom: '2rem'}}>Ce message se fermera automatiquement dans 5 secondes</p>
      <video id="gif-mp4" poster={`https://media.giphy.com/media/${hash}/200_s.gif`} style={{margin: 0, padding: 0}}
             width="358" height="360" autoPlay={true} loop={true} muted={true}>
        <source src={`https://media.giphy.com/media/${hash}/giphy.mp4`}
                type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"/>
        <img src={`https://media.giphy.com/media/${hash}/giphy.gif`} alt="Merci"
             title="Your browser does not support the mp4 video codec."/>
      </video>
      <h1 className="is-size-1 has-text-centered has-text-primary">
        <FontAwesomeIcon icon={faHeart}/>&nbsp;<br/>Merci !
      </h1>
    </div>
  );
};

export default ThankYouMessage
