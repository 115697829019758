import React, {useContext, useEffect} from "react";
import {ListItem} from "../models/ListItem";
import ReservationPanel from "./reservation/ReservationPanel";
import ContributionPanel from "./contribution/ContributionPanel";
import ContributionItemCard from "./ItemCard/ContributionItemCard";
import {ModalContext} from "../contexts/ModalContext";
import ReservationItemCard from "./ItemCard/ReservationItemCard";
import {useMedia} from "react-use";
import {useCurrentRoute} from "react-navi";
import Panel from "./Panel";

const WishlistModal: React.FC<{ items: ListItem[] }> = ({items}) => {
  const {
    isModalOpen,
    closeModal,
    item,
    setItem,
    openModal
  } = useContext(ModalContext);

  const isWide = useMedia('(min-width: 780px)');

  const route = useCurrentRoute();
  const routeItemId = (
    route.lastChunk.request
    && route.lastChunk.request.params.id
  );

  useEffect(() => {
    if (routeItemId) {
      const foundItem = items.find((i: ListItem) => i.id === routeItemId);
      if (foundItem) {
        setItem(foundItem);
        openModal();
      }
    }
  }, [items, setItem, openModal, routeItemId]);

  if (!item.id) return null;

  return (
    <div className={"modal modal-fx-fadeInScale " + (isModalOpen ? " is-active " : "")}>

      <div className="modal-background" onClick={closeModal}/>

      <div className="modal-content">
        <div className="card-content">
          <div className="columns is-centered">

            {isWide && (
              <div className="column is-3" style={{maxWidth: "300px"}}>
                {item.reservationOnly && <ReservationItemCard item={item} interactable={false}/>}
                {!item.reservationOnly && <ContributionItemCard item={item} interactable={false}/>}
              </div>
            )}

            <div className="column is-5" style={{maxWidth: "530px"}}>
              {item.reservationOnly && (
                <Panel title="Réserver un cadeau">
                  <ReservationPanel item={item}/>
                </Panel>
              )}
              {!item.reservationOnly && (
                <Panel title="Contribuer à l'achat d'un cadeau">
                  <ContributionPanel item={item}/>
                </Panel>
              )}
            </div>

          </div>
        </div>
      </div>

      {isWide && <button aria-label="close" className="modal-close is-large" onClick={closeModal}/>}

    </div>
  );
};

export default WishlistModal
