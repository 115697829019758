import React, {FC, useContext} from "react";
import {ListItem, useWishListItems} from "../models/ListItem";
import ReservationGridItem from "./reservation/ReservationGridItem";
import {useOrder} from "../contexts/OrderByContext";
import {WishlistContext} from "../contexts/WishlistContext";
import WishlistModal from "./WishlistModal";
import ContributionGridItem from "./contribution/ContributionGridItem";

export const AppGrid: FC = () => {
  const {order} = useOrder();
  const list = useContext(WishlistContext);
  const listItems = useWishListItems(list.id, order);
  return (
    <>
      <WishlistModal items={listItems}/>
      <section className="container">
        <div className="columns is-multiline">
          {
            listItems.map((listi: ListItem) => (
              <div className="column is-3" key={listi.id}>
                {listi.reservationOnly
                  ? <ReservationGridItem item={listi}/>
                  : <ContributionGridItem item={listi}/>
                }
              </div>
            ))
          }
        </div>
      </section>
    </>
  );
};
