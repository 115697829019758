import React, {useContext} from "react";
import {useDeleteContribution} from "../../../models/Contribution";
import {ContributionContext} from "../../../contexts/ContributionContext";
import LinkButton from "../../LinkButton";
import {ModalContext} from "../../../contexts/ModalContext";

const CancelLink: React.FC<{ contributionId: string }> = ({contributionId}) => {
  const [{fetching, error}, deleteMutation] = useDeleteContribution();
  const {remove: deleteContext} = useContext(ContributionContext);
  const {closeModal} = useContext(ModalContext);

  if (error) {
    return <>Erreur :(</>;
  }

  const onClick = () => {
    deleteMutation({id: contributionId}).then(deleteContext);
    closeModal();
  };

  return (
    <LinkButton loading={fetching} onClick={onClick}>
      Non, j'ai changé d'avis (Supprimer ma contribution)
    </LinkButton>
  )
};

export default CancelLink