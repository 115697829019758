import React, {FC, useContext} from "react";
import {faGift, faLock} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ListItem} from "../../models/ListItem";
import ReservationItemCard from "../ItemCard/ReservationItemCard";
import useReservationStatus from "./useReservationStatus";
import {ContributorContext} from "../../contexts/ContributorContext";

const UnlockedItem: FC<{ item: ListItem, reason: string }> = ({item, reason}) => (
  <ReservationItemCard item={item} interactable={true} active={true}>
    <footer className="card-footer has-text-centered" style={{padding: "1rem"}}>
      <button className="button is-primary is-outlined is-fullwidth">
        <span className="icon is-small"><FontAwesomeIcon icon={faGift}/></span>
        <span>{reason}</span>
      </button>
    </footer>
  </ReservationItemCard>
);

const LockedItem: FC<{ item: ListItem, reason: string }> = ({item, reason}) => {
  const {contributor} = useContext(ContributorContext);
  return (
    <>
      <ReservationItemCard item={item} interactable={false} active={false}>
        <footer className="card-footer has-text-centered">
          <div className="card-footer-item">
            <p className="is-primary is-size-7">
              <FontAwesomeIcon icon={faLock}/> &nbsp;&nbsp;{reason}
            </p>
          </div>
        </footer>
      </ReservationItemCard>
      {contributor.email === 'lia.spaccesi@gmail.com' && (<pre>{JSON.stringify(item.reservation, null, 2)}</pre>)}

    </>
  );
};

const ReservationGridItem: FC<{ item: ListItem }> = ({item}) => {
  switch (useReservationStatus(item)) {
    case "NOT RESERVED":
      return <UnlockedItem item={item} reason="Réserver"/>
    case "STARTED BY CURRENT USER":
      return <UnlockedItem item={item} reason="Confirmer ma réservation"/>
    case "FINALIZED BY CURRENT USER":
      return <LockedItem item={item} reason="Vous avez réservé cet objet"/>
    case "FINALIZED BY SOMEONE":
    case "STARTED BY SOMEONE":
      return <LockedItem item={item} reason="Cet objet est déjà réservé"/>
  }
};

export default ReservationGridItem
