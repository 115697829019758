import React, {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import {makePaypalLink} from "../ContributionSubmitter/utils";
import ConfirmButton from "./ConfirmButton";
import ConfirmLaterButton from "../../reservation/confirmation/ConfirmLaterButton";
import CancelLink from "./CancelLink";
import {ContributionContext} from "../../../contexts/ContributionContext";

const ContributionConfirmationForm: React.FC = () => {
  const {contribution} = useContext(ContributionContext);
  if (!contribution) return null;
  return (
    <div className="field">

      <article className="message is-link">
        <div className="message-body">

          Une fenêtre vient de s'ouvrir sur le site Paypal pour vous permettre d'effectuer votre paiement.<br/><br/>
          Si vous avez fermé cette fenêtre par erreur, vous pouvez toujours cliquer sur le lien ci-dessous:

          <div className="has-text-centered has-text-primary" style={{margin: '2rem 0'}}>
            <a className="is-link is-size-6" href={makePaypalLink(contribution.amount)} target="_blank"
               rel="noopener noreferrer">
              <FontAwesomeIcon icon={faExternalLinkAlt}/> &nbsp;Finaliser ma contribution sur Paypal</a>
          </div>

          Une fois le paiement effectué, n'oubliez pas de confirmer votre achat sur cette page ou via l'e-mail qui vient
          de vous être envoyé.
        </div>
      </article>

      <label className="label has-text-centered">Avez-vous finalisé votre contribution pour cet article ?</label>

      <div className="field is-grouped has-text-centered">
        <div className="control  is-expanded has-text-centered">

          <ConfirmButton contributionId={contribution.id}/>
          &nbsp;
          <ConfirmLaterButton/>
          <br/>
          <br/>
          <CancelLink contributionId={contribution.id}/>

        </div>
      </div>

    </div>
  );
};

export default ContributionConfirmationForm
