import React, {FC, memo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {SortOrder} from "./SortLink";

const OrderIcon: FC<{ direction: SortOrder }> = memo(({direction}) => (
  <>
      <span className="icon is-small">
        <FontAwesomeIcon icon={direction === '_DESC' ? faAngleDown : faAngleUp}/>
      </span> &nbsp;
  </>
));

export default OrderIcon