import React, {createContext, FC, useContext, useState} from "react";
import {ReservationContext} from "../../../contexts/ReservationContext";
import {ContributorContext} from "../../../contexts/ContributorContext";
import {Reservation} from "../../../models/Reservation";
import {
  WishlistFormDisclaimer,
  WishlistFormEmail,
  WishlistFormMessage,
  WishlistFormName,
  WishlistFormPrivacy,
} from "../../WishlistForm";
import ReservationFormSubmit from "./ReservationFormSubmit";
import {Contributor} from "../../../models/Contributor";
import {WishlistContext} from "../../../contexts/WishlistContext";

export type ReservationFormField = "name" | "email"

interface IReservationFormContext {
  invalidFields: ReservationFormField[]
  setInvalidFields: (s: ReservationFormField[]) => void
  contributor: Contributor
  message: string
}

export const ReservationFormContext = createContext<IReservationFormContext>({} as IReservationFormContext);

const ReservationForm: FC = () => {
  const [message, setMessage] = useState('');
  const {contributor, setContributor} = useContext(ContributorContext);
  const {setReservation, item} = useContext(ReservationContext);
  const {authorNames} = useContext(WishlistContext);

  const updateContributor = (state: any) => {
    setContributor({
      ...contributor,
      [state.target.name]: state.target.value
    });
  };

  const [invalidFields, setInvalidFields] = useState<ReservationFormField[]>([]);

  const context = {
    contributor,
    invalidFields,
    message,
    setInvalidFields
  };

  return (

    <div className="columns is-centered">
      <div className="column is-12">

        <ReservationFormContext.Provider value={context}>

          <WishlistFormDisclaimer title="Vous êtes sur le point de réserver cet objet.">
            <strong>Vous pourrez vous procurer cet objet sur le site internet indiqué, ou sur n'importe quel autre site internet,
              ou en magasin.</strong>
            <br/>
            <br/>
            Il apparaîtra comme "déjà réservé" pour les autres visiteurs, qui ne pourront plus l'acheter, pour
            éviter les
            achats en double.
          </WishlistFormDisclaimer>

          <WishlistFormPrivacy authorNames={authorNames} />

          <WishlistFormName
            defaultName={contributor.name}
            onChange={updateContributor}
          />

          <WishlistFormEmail
            defaultEmail={contributor.email}
            onChange={updateContributor}
          />

          <WishlistFormMessage
            authorNames={authorNames}
            onChange={setMessage}
          />

          <ReservationFormSubmit
            itemId={item.id}
            terms="Je m'engage sur l'honneur à acheter cet objet."
            onSubmitted={(reservation: Reservation) => {
              setReservation(reservation);
              setContributor(reservation.contributor)
            }}
          />

        </ReservationFormContext.Provider>

      </div>
    </div>

  );
};

export default ReservationForm
