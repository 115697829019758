import emailjs from "emailjs-com";
import {useMutation} from "urql";
import uuid from 'uuid/v4'
import {EMAILJS_USER_ID, EMAILS_REPLY_TO, WISHLIST_BASE_URL_ABSOLUTE} from "../../../constants";

export const sendLoginEmail = (toEmail: string, authorNames: string, loginUrl: string) => {
  emailjs.send(
    'default_service',
    'nouvelle_connexion',
    {
      replyTo: EMAILS_REPLY_TO,
      toEmail,
      authorNames,
      loginUrl
    },
    EMAILJS_USER_ID
  );
};

const createMagicLinkMutation = `
mutation($email: String!, $token: String!) {
  upsertMagicLink(
    where: { email: $email }
    create: {
      email: $email
      token: $token
      enabled: true
      contributor: { connect: { email: $email } }
    }
    update: { token: $token, enabled: true }
  ) {
    id
  }
}
`;

export const useLoginUrl: () => (any | [(email: string) => Promise<string>]) = () => {
  const [{fetching}, createMagicLink] = useMutation(createMagicLinkMutation);

  const make: (email: string) => Promise<string> = async (email) => {
    const token = uuid();
    await createMagicLink({email, token});
    return `${WISHLIST_BASE_URL_ABSOLUTE}/t/${token}`;
  };

  return [{fetching}, make]
};
