import React, {FC, memo, useContext} from "react";
import {ModalContext} from "../../contexts/ModalContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import {parseDomain} from "../../utils";
import {ListItem} from "../../models/ListItem";
import {useMedia} from "react-use";

export const ItemCardImage: FC<{ imageUrl: string }> = memo(({imageUrl}) => (
  <div className="card-image">
    <figure className="image">
      <img alt="Placeholder" className="modal-button" src={imageUrl}/>
    </figure>
  </div>
));

export const ItemCardContainer: FC<{ active: boolean, item?: ListItem }> = ({active, item, children}) => {
  const {openModal, setItem} = useContext(ModalContext);
  const onClick = () => {
    if (!item) return;
    setItem(item);
    openModal();
  };

  const isWide = useMedia('(min-width: 780px)');
  const style = isWide ? {width: '100%'} : {width: '90%', margin: '0 5%'}
  return (
    <div className={"card has-ribbon " + (active ? 'is-shady' : '')}
         style={{...style, ...(!active && {opacity: .5})}}
         onClick={active ? onClick : () => null}>
      {children}
    </div>
  )
};

export const ItemCardTitle: FC<{ title: string }> = memo(({title}) => (
  <header className="card-header has-text-centered" style={{padding: "1rem"}}>
    <div className="card-header-title ">
      <div className="title is-6 has-text-centered">{title}</div>
    </div>
  </header>
));

export const ItemCardLink: FC<{ linkUrl: string }> = memo(({linkUrl}) => (
  <footer className="card-footer">
    <div className="card-footer-item">
      <a className="is-link is-size-7" href={linkUrl} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faExternalLinkAlt}/> &nbsp;
        Voir sur {parseDomain(linkUrl)}</a>
    </div>
  </footer>
));

export const ItemCardMessage: FC<{ message: string }> = memo(({message}) => (
  <div className="card-content">
    <div className="content">
      <div className="message is-link">
        <div className="message-body">
          <p>{message}</p>
        </div>
      </div>
    </div>
  </div>
));

interface ILinkIf {
  condition: boolean
  href: string
  onClick?: (event: any) => void
}

export const LinkIf: FC<ILinkIf> = ({condition, children, ...linkProps}) =>
  condition
    ? <div onClick={() => window.history.pushState(null, document.title, linkProps.href)}>{children}</div>
    : <>{children}</>;
