import React, {FC, useContext, useEffect} from "react";
import {sendLoginEmail, useLoginUrl} from "../utils";
import {WishlistContext} from "../../../../contexts/WishlistContext";
import localforage from 'localforage'
import {LoginAttempt, LoginContext} from "./LoginContext";
import {useQuery} from "urql";

const getContributorByEmailQuery = `
query($email: String!) {
  contributor(where: { email: $email }) {
    id
  }
}
`;

const LoginButton: FC<{ email: string | null, onClick: () => void, onEmailNotFound: () => void }> = ({email, onClick, onEmailNotFound}) => {
  const [{fetching}, makeLoginUrl] = useLoginUrl();
  const [{data}] = useQuery({
    query: getContributorByEmailQuery,
    variables: {email},
  });
  const list = useContext(WishlistContext);
  const {
    setLoginAttempts,
    sendLoginLink
  } = useContext(LoginContext);

  useEffect(() => {
    localforage.getItem<LoginAttempt[]>('login-attempts')
      .then((data) => {
        setLoginAttempts(data || []);
      })
  }, [setLoginAttempts]);

  const onSubmit = () => {
    if (!email) return;
    if (!!(data?.contributor?.id)) {
      sendLoginLink(email, () => {
        // send login email
        makeLoginUrl(email).then((linkUrl: string) => {
          sendLoginEmail(email, list.authorNames, linkUrl);
        });
        // succeed
        alert("Vous venez de recevoir un e-mail.\nVotre lien de connexion se trouve à l'intérieur.");
        onClick();
      }, () => {
        // fail
        alert("Vous venez de recevoir un e-mail.\nVotre lien de connexion se trouve à l'intérieur.");
        onClick();
      })
    } else {
      onEmailNotFound()
    }
  };

  const className = "button is-primary" + (fetching ? " is-loading " : "");

  return (
    <button className={className} onClick={onSubmit}>Connexion</button>
  )
};

export default LoginButton