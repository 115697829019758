import React, {FC} from "react";
import {useOrder} from "../contexts/OrderByContext";
import OrderIcon from "./OrderIcon";

export type SortOrder = "_DESC" | "_ASC";

interface ISortLink {
  fieldName: string,
  label: string
}

const SortLink: FC<ISortLink> = ({fieldName, label}) => {
  const {direction, changeOrder, isActive} = useOrder();
  const className = "navbar-item" + (isActive(fieldName) ? " is-active" : "");
  return (
    <span style={{cursor: 'pointer'}} className={className} onClick={() => changeOrder(fieldName)}>
      {isActive(fieldName) && <span className="icon"><OrderIcon direction={direction}/></span>}
      <span className={isActive(fieldName) ? "has-text-primary" :""}>{label}</span>
    </span>
  )
};

export default SortLink
