import React, {FC, useContext} from "react";
import {Contribution} from "../../../models/Contribution";
import {makeItemUrl} from "../../ItemCard/makeItemUrl";
import {Link} from "react-navi";
import {DropdownSubtitle} from "./HeaderDropdown";
import {ModalContext} from "../../../contexts/ModalContext";

export const HeaderContributions = ({contributions}: { contributions: Contribution[] }) => {
  if (!contributions.length) {
    return <div className="dropdown-item">Vous n'avez pas encore contribué.</div>
  }
  return <>
    {contributions.map((c: Contribution) => (
      c.isFinalized
        ? <FinalizedContribution key={c.id} contribution={c}/>
        : <ContributionNeedsConfirmation key={c.id} contribution={c}/>)
    )}
  </>
};

export const FinalizedContribution: FC<{ contribution: Contribution }> = ({contribution}) => (
    <div className="dropdown-item">
      <div className="columns is-mobile">
        <div className="column is-3">
          <img src={contribution.item.imageUrl || ""} alt={contribution.item.title}/>
        </div>
        <div className="column is-9">
          <DropdownSubtitle date={contribution.createdAt}/>
          Vous avez contribué <strong>{contribution.amount}€</strong>&nbsp;
          pour <strong>{contribution.item.title}</strong>
        </div>
      </div>
    </div>
);

export const ContributionNeedsConfirmation: FC<{ contribution: Contribution }> = ({contribution}) => {
  const {openModal} = useContext(ModalContext);
  return (
      <Link href={makeItemUrl(contribution.item)} onClick={openModal}>
        <div className="dropdown-item">
          <div className="columns is-mobile">
            <div className="column is-3">
              <img src={contribution.item.imageUrl || ""} alt={contribution.item.title}/>
            </div>
            <div className="column is-9">
              <DropdownSubtitle date={contribution.createdAt}/>
              Vous avez contribué <strong>{contribution.amount}€</strong>&nbsp;
              pour <strong>{contribution.item.title}</strong>
              &nbsp;&nbsp;<span className="has-text-link">Confirmer</span>
            </div>
          </div>
        </div>
      </Link>
  );
};