import React, {FC} from "react";

const ItemCardPrice: FC<{ remainingPrice: string, totalPrice: string }> = ({remainingPrice, totalPrice}) => (
  <footer className="card-footer">
    <div className="card-footer-item">
      <nav className="level">
        <div className="level-item card-footer-item has-text-centered">
          <div>
            <p className="heading">Prix total</p>
            <p className="subtitle is-7">
              {totalPrice === remainingPrice && <>{totalPrice}</>}
              {totalPrice !== remainingPrice && <s>{totalPrice}</s>}
            </p>
          </div>
        </div>
      </nav>
    </div>

    <div className="card-footer-item">
      <nav className="level">
        <div className="level-item card-footer-item has-text-centered">
          <div>
            <p className="heading has-text-weight-bold">Prix restant</p>
            <p className="subtitle has-text-primary has-text-weight-bold is-6">{remainingPrice}</p>
          </div>
        </div>
      </nav>
    </div>
  </footer>
);

export default ItemCardPrice
