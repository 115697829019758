import {Contribution} from "./Contribution";
import {useMutation} from "urql";

export interface Contributor {
  id?: string
  name: string
  email: string
  phone?: string
  contributions: Contribution[]
}

interface UpsertContributorVariables {
  name: string,
  email: string,
  phone?: string
}

const upsertContributorMutation = `
mutation($name: String!, $email: String!, $phone: String) {
  upsertContributor(
    where: { email: $email }
    create: { name: $name, email: $email, phone: $phone }
    update: { name: $name, phone: $phone }
  ) {
    id
    email
  }
}
`;

export const useUpsertContributor = () => {
  return useMutation<Contributor, UpsertContributorVariables>(upsertContributorMutation);
};
