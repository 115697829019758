import Slider from "rc-slider";
import React, {FC, ReactElement} from "react";
import {getRemainingPrice, ListItem} from "../../models/ListItem";
import formatPrice from "../formatPrice";

const sliderStyle = {
  trackStyle: {
    backgroundColor: ' #006788',
    transition: 'ease-out 1s all'
  },
  dotStyle: {
    display: "none",
  }
};

const makeMarks: (item: ListItem) => { [p: number]: ReactElement | string } = (item: ListItem) => {
  const remainingPrice = getRemainingPrice(item);
  return ({
    [(item.totalPrice * 100) - (remainingPrice * 100)]: <strong>{formatPrice(item.totalPrice - remainingPrice)}</strong>,
    [item.totalPrice * 100]: formatPrice(item.totalPrice)
  });
};

const ItemCardSlider: FC<{ item: ListItem, remainingPrice: number }> = ({remainingPrice, item}) => {
  return (
    <Slider min={0} marks={makeMarks(item)} included={true}
            value={(item.totalPrice*100) - (remainingPrice*100)} max={item.totalPrice*100}
            trackStyle={sliderStyle.trackStyle}
            dotStyle={sliderStyle.dotStyle}
            handleStyle={sliderStyle.dotStyle}/>
  )
};

export default ItemCardSlider