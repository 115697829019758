import React, {FC, memo} from "react";
import SortLink from "./SortLink";
import WishlistCompletion from "./WishlistCompletion";

export const HeroBottomBar: FC = memo(() => (
  <div className="box cta">
    <div className="container">

      <WishlistCompletion/>

      <div className="navbar-menu is-active" style={{marginTop: "1.5rem"}}>
        <div className="navbar-start has-text-centered" style={{margin: "auto"}}>
          <span className="navbar-item is-active">Trier :</span>
          <SortLink fieldName="totalPrice" label="Par prix total"/>
          <SortLink fieldName="title" label="Par nom"/>
          <SortLink fieldName="linkUrl" label="Par magasin"/>
          <SortLink fieldName="reservationOnly" label="Par type (contribution / réservation)"/>
        </div>
      </div>

    </div>
  </div>
));
