import React, {FC} from "react";
import ThankYouMessage from "../ThankYouMessage";
import ReservationConfirmationForm from "./confirmation/ReservationConfirmationForm";
import ReservationForm from "./ReservationForm/ReservationForm";
import useReservationStatus from "./useReservationStatus";
import {ListItem} from "../../models/ListItem";

const ReservationPanel: FC<{ item: ListItem }> = ({item}) => {
  switch (useReservationStatus(item)) {
    case "NOT RESERVED":
      return <ReservationForm/>
    case "FINALIZED BY CURRENT USER":
      return <ThankYouMessage/>;
    case "STARTED BY CURRENT USER":
      return <ReservationConfirmationForm/>
    case "FINALIZED BY SOMEONE":
    case "STARTED BY SOMEONE":
      return <>Désolé, cet objet est déjà réservé !</>;
  }
};

export default ReservationPanel
