import React, {FC, useContext, useEffect, useState} from "react";
import {ListItem} from "../models/ListItem";
import {ModalContext} from "./ModalContext";
import {Reservation} from "../models/Reservation";
import {hasFinalizedReservation, hasStartedReservation} from "../components/reservation/useReservationStatus";

interface ReservationContext {
  item: ListItem
  hasStarted: boolean
  hasFinalized: boolean
  remove: () => void
  confirm: () => void
  reset: () => void
  setReservation: (reservation: Reservation) => void
}

export const ReservationContext = React.createContext<ReservationContext>({} as ReservationContext);

export const ReservationContextProvider: FC = ({children}) => {
  const {item: modalItem} = useContext(ModalContext);
  const [item, updateItem] = useState<ListItem>(modalItem);

  const [hasStarted, setStarted] = useState<boolean>(hasStartedReservation(item));
  const [hasFinalized, setFinalized] = useState<boolean>(hasFinalizedReservation(item));

  useEffect(() => {
    updateItem(modalItem);
    setStarted(hasStartedReservation(modalItem));
    setFinalized(hasFinalizedReservation(modalItem));
  }, [modalItem]);

  const context = {
    item,
    hasStarted,
    hasFinalized,
    setReservation: (reservation: Reservation) => {
      updateItem(item => ({...item, reservation}) as ListItem);
      setStarted(true);
      setFinalized(false);
      console.log('reservation.set', reservation);
    },
    remove: () => {
      updateItem(item => ({...item, reservation: null}));
      setStarted(false);
      setFinalized(false);
      console.log('reservation.remove');
    },
    confirm: () => {
      updateItem(item => ({
        ...item,
        reservation: {
          ...item.reservation,
          isFinalized: true
        }
      }) as ListItem);
      setStarted(true);
      setFinalized(true);
      console.log('reservation.confirm');
    },
    reset: () => {
      setStarted(false);
      setFinalized(false);
      console.log('reservation.reset');
    }
  };

  return (
    <ReservationContext.Provider value={context}>{children}</ReservationContext.Provider>
  )
};