import {CombinedError, OperationResult} from "urql";
import React, {useContext} from "react";
import {Contribution, useCreateContribution} from "../../../models/Contribution";
import {ContributionFormContext} from "../ContributionForm/ContributionForm";
import {makePaypalLink, sendContributionEmail} from "./utils";
import {OPEN_PAYPAL, SEND_EMAILS} from "../../../constants";
import {useUpsertContributor} from "../../../models/Contributor";
import SubmitterButton from "./SubmitterButton";
import {ContributionContext} from "../../../contexts/ContributionContext";
import formatPrice from "../../formatPrice";
import validateContributor, {removeSpaces} from "../../../validateContributor";

interface ContributionSubmitterProps {
  disabled: boolean
  onSubmitted: (contribution: Contribution) => void
}

const useContributionSubmit: (onSubmitted: (c: Contribution) => void) => [{ fetching: boolean; error?: CombinedError }, () => void] = (onSubmitted: (c: Contribution) => void) => {
  const {item} = useContext(ContributionContext);
  const {contributionValue, message, contributor} = useContext(ContributionFormContext);
  const [{fetching: fetchingContributor, error: errorContributor}, upsertContributor] = useUpsertContributor();
  const [{fetching: fetchingContribution, error: errorContribution}, createContribution] = useCreateContribution();

  const submitContribution = () => {
    upsertContributor(contributor)
      .then((res) => {
        console.log('Upserted contributor', res);
        if (res.error) return;
        createContribution({
          amount: contributionValue,
          itemId: item.id,
          email: removeSpaces(contributor.email),
          message
        })
          .then((res: OperationResult) => {
            console.log('Created contribution')
            SEND_EMAILS && sendContributionEmail(contributor.name, contributor.email, item, contributionValue);
            OPEN_PAYPAL && window.open(makePaypalLink(contributionValue), "_blank");
            onSubmitted(res.data.createContribution);
          });
      })
  };

  return [{
    fetching: fetchingContribution || fetchingContributor,
    error: errorContribution || errorContributor
  }, submitContribution];
};

const ContributionSubmitter: React.FC<ContributionSubmitterProps> = ({disabled, onSubmitted}) => {
  const {contributionValue, contributor} = useContext(ContributionFormContext);
  const [{fetching, error}, submitContribution] = useContributionSubmit(onSubmitted);

  if (error) return <>Erreur :(</>;

  return (
    <SubmitterButton
      outlined
      fullWidth
      loading={fetching}
      disabled={disabled}
      onClick={() => {
        if (!validateContributor(contributor)) {
          alert('Merci de renseigner votre nom et votre e-mail.');
          return;
        }
        submitContribution();
      }}>
      Contribuer&nbsp;{formatPrice(contributionValue)}
    </SubmitterButton>
  )
};

export default ContributionSubmitter