import React, {useContext} from "react";
import {useDeleteReservation} from "../../../models/Reservation";
import {ReservationContext} from "../../../contexts/ReservationContext";
import {ModalContext} from "../../../contexts/ModalContext";
import LinkButton from "../../LinkButton";

const CancelLink: React.FC<{ reservationId: string }> = ({reservationId}) => {
  const [{fetching, error}, deleteMutation] = useDeleteReservation();
  const {remove: deleteContext} = useContext(ReservationContext);
  const {closeModal} = useContext(ModalContext);

  if (error) {
    return <>Erreur :(</>;
  }

  const onClick = () => {
    deleteMutation({id: reservationId}).then(deleteContext);
    closeModal();
  };

  return (
    <LinkButton loading={fetching} onClick={onClick}>
      Non, j'ai changé d'avis (Supprimer ma réservation)
    </LinkButton>
  )
};

export default CancelLink