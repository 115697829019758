import React, {ReactNode, useState} from "react";

export interface TermsAcceptanceProviderReturn {
  hasAcceptedTerms: boolean,
  denyTerms: () => void,
  makeInput: (itemId: string, terms?: string) => ReactNode
}

export const TermsAcceptanceContext = React.createContext({} as TermsAcceptanceProviderReturn);

const TermsAcceptanceProvider = ({children}: { children: any }) => {
  const [hasAcceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const context = {
    hasAcceptedTerms,
    denyTerms: () => setAcceptedTerms(false),
    makeInput: (itemId: string, terms: string = 'J\'ai lu et j\'accepte les conditions ci-dessus') => (
      <>
        <input className="is-checkradio" id={"hasAcceptedTerms" + itemId}
               type="checkbox" name="hasAcceptedTerms"
               checked={hasAcceptedTerms}
               onChange={() => setAcceptedTerms(!hasAcceptedTerms)}
        />
        <label htmlFor={"hasAcceptedTerms" + itemId}>{terms}</label>
      </>
    )
  };
  return (
    <TermsAcceptanceContext.Provider value={context}>
      {children}
    </TermsAcceptanceContext.Provider>
  )
};

export default TermsAcceptanceProvider