import React, {FC, useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {ModalContext} from "../../../contexts/ModalContext";

const ConfirmLaterButton: FC = () => {
  const {closeModal} = useContext(ModalContext);
  return (
    <button
      onClick={closeModal}
      className="button is-primary is-outlined is-rounded">
      <span className="icon"><FontAwesomeIcon icon={faClock}/></span>
      <span>Plus tard</span>
    </button>
  )
};

export default ConfirmLaterButton