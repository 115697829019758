import React, {FC, useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import {parseDomain} from "../../../utils";
import ConfirmButton from "./ConfirmButton";
import CancelLink from "./CancelLink";
import ConfirmLaterButton from "./ConfirmLaterButton";
import {ReservationContext} from "../../../contexts/ReservationContext";

const ReservationConfirmationForm: FC = (() => {
  const {item} = useContext(ReservationContext);
  if (!item.reservation) return null;
  return (
    <div className="field">

      <article className="message is-link">
        <div className="message-body">

          Vous trouverez les informations sur cet objet en cliquant sur le lien indiqué ci-dessous.
          <div className=" has-text-centered has-text-primary  " style={{margin: '2rem 0'}}>
            <a className="is-link is-size-6" href={item.linkUrl} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faExternalLinkAlt}/> &nbsp;
              Voir sur {parseDomain(item.linkUrl)}</a>
          </div>
          Vous pouvez l'acheter sur ce site, ou sur n'importe quel autre site internet ou en magasin.<br/><br/>
          Vous pouvez le faire livrer chez vous ou directement chez {item.list.authorNames} à l'adresse suivante
          :<br/><br/>
          M et Mme Bouisset<br/> 283, rue du Pré aux Clercs<br/>Res Central Parc Bat K2 Apt 30<br/> 34090 Montpellier
          <br/><br/>
          Une fois acheté, n'oubliez pas de confirmer votre achat sur cette page ou via l'e-mail qui vient de vous être
          envoyé.

        </div>
      </article>

      <label className="label has-text-centered">Avez-vous finalisé l'achat de cet article ?</label>

      <div className="field is-grouped has-text-centered">
        <div className="control is-expanded has-text-centered">
          <ConfirmButton reservationId={item.reservation.id}/>
          &nbsp;
          <ConfirmLaterButton/>
          <br/>
          <br/>
          <CancelLink reservationId={item.reservation.id}/>
        </div>
      </div>

    </div>
  );
});

export default ReservationConfirmationForm