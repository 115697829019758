import {ListItem} from "../../../models/ListItem";
import emailjs from "emailjs-com";
import {renderToString} from "react-dom/server";
import * as React from "react";
import {EmailReservationItemCard} from "../../ItemCard/ReservationItemCard";
import {EMAILJS_USER_ID, EMAILS_REPLY_TO, WISHLIST_BASE_URL_ABSOLUTE} from "../../../constants";

export const sendReservationEmail = (contributorName: string, contributorEmail: string, item: ListItem) => {
  emailjs.send(
    'default_service',
    'nouvelle_resa',
    {
      replyTo: EMAILS_REPLY_TO,
      toName: contributorName,
      toEmail: contributorEmail,
      confirmLink: `${WISHLIST_BASE_URL_ABSOLUTE}/i/${item.id}`,
      messageHtml: renderToString(<EmailReservationItemCard item={item}/>)
    },
    EMAILJS_USER_ID
  );
};
