import {getRemainingPrice, ListItem} from "../../../models/ListItem";
import emailjs from "emailjs-com";
import {renderToString} from "react-dom/server";
import * as React from "react";
import {FC} from "react";
import {makeItemUrl} from "../../ItemCard/makeItemUrl";
import {EMAILJS_USER_ID, EMAILS_REPLY_TO, WISHLIST_BASE_URL_ABSOLUTE} from "../../../constants";
import formatPrice from "../../formatPrice";

const EmailContributionItemCard: FC<{ item: ListItem }> = ({item}) => {
  const remainingPrice = getRemainingPrice(item);
  return (
    <>
      <a href={makeItemUrl(item, true)}>
        {item.imageUrl && <div><img alt={item.title} src={item.imageUrl} width="100%"/></div>}
        <br/>
        <div>{item.title}</div>
      </a>
      <br/>
      {remainingPrice !== item.totalPrice && (
        <>
          Prix total: <s>{formatPrice(item.totalPrice)}</s>
          <br/>
          Prix restant: {formatPrice(remainingPrice)}
          <br/>
        </>
      )}
      {remainingPrice === item.totalPrice && (
        <>
          Prix: {formatPrice(item.totalPrice)}
          <br/>
        </>
      )}
      Message:
      <br/>
      <pre>{item.message}</pre>

    </>
  );
};

export const sendContributionEmail = (contributorName: string, contributorEmail: string, item: ListItem, amount: number) => {
  emailjs.send(
    'default_service',
    'nouvelle_contrib',
    {
      replyTo: EMAILS_REPLY_TO,
      toName: contributorName,
      toEmail: contributorEmail,
      amount,
      confirmLink: `${WISHLIST_BASE_URL_ABSOLUTE}/i/${item.id}`,
      messageHtml: renderToString(<EmailContributionItemCard item={item}/>)
    },
    EMAILJS_USER_ID
  );
};

export const makePaypalLink = (contributionValue: number) =>
  `https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=6W94ETHHZJCLG&currency_code=EUR&amount=${contributionValue}&source=url`;
