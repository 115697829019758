import React, {FC} from "react";
import {Contributor} from "../../../models/Contributor";
import useContributions from "./useContributions";
import {HeaderContributions} from "./DropdownContribution";
import {HeaderReservations} from "./DropdownReservation";
import Moment from "react-moment";
import 'moment/locale/fr';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faPowerOff} from "@fortawesome/free-solid-svg-icons";
import {useMedia} from "react-use";

export const DropdownSubtitle: FC<{ date: string }> = ({date}) => (
  <div className="heading subtitle has-text-primary" style={{
    color: "#757763",
    marginBottom: '0.5rem'
  }}>
    <Moment fromNow locale="fr" date={date}/>
  </div>
);

const HeaderDropdown: FC<{ contributor: Contributor }> = ({contributor}) => {
  const {
    fetching,
    contributions,
    reservations
  } = useContributions(contributor.email);

  const isWide = useMedia('(min-width: 780px)');

  if (fetching) return <>Chargement...</>;

  const contributionsCount = contributions.length + reservations.length;

  return (
    <div className={"dropdown is-hoverable" + (isWide ? " is-right " : "")}>

      <div className="dropdown-trigger">

        <button className="button is-primary"
                aria-haspopup="true"
                aria-controls="dropdown-menu2">
          <span>Ma contribution {contributionsCount > 0 && (`(${contributionsCount})`)}</span>
          <span className="icon is-small"><FontAwesomeIcon icon={faAngleDown}/></span>
        </button>
      </div>

      <div className="dropdown-menu" id="dropdown-menu2" role="menu">
        <div className="dropdown-content">
          <div className="dropdown-item">
            <span className="heading subtitle has-text-centered has-text-dark has-text-weight-bold">Contributions</span>
          </div>
          <HeaderContributions contributions={contributions}/>

          <hr className="dropdown-divider"/>

          <div className="dropdown-item">
            <span className="heading subtitle has-text-centered has-text-dark has-text-weight-bold">Réservations</span>
          </div>

          <HeaderReservations reservations={reservations}/>

          <hr className="dropdown-divider"/>

          <a href="/deconnexion" className="dropdown-item has-icon">
            <span className="icon"><FontAwesomeIcon icon={faPowerOff}/></span>
            <span>Me déconnecter</span>
          </a>

        </div>
      </div>
    </div>
  )
};

export default HeaderDropdown