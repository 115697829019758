import React, {useContext} from "react";
import ThankYouMessage from "../ThankYouMessage";
import ContributionConfirmationForm from "./confirmation/ContributionConfirmationForm";
import ContributionForm from "./ContributionForm/ContributionForm";
import {ContributionContext} from "../../contexts/ContributionContext";
import useContributionStatus from "./useContributionStatus";
import {ListItem} from "../../models/ListItem";

const ContributionPanel: React.FC<{ item: ListItem }> = ({item}) => {
  const {reset} = useContext(ContributionContext);

  switch (useContributionStatus(item)) {
    case "FINALIZED BY CURRENT USER":
      return <ThankYouMessage onClose={reset}/>;
    case "STARTED BY CURRENT USER":
      return <ContributionConfirmationForm/>;
    case "FULLY CONTRIBUTED":
      return <>Désolé, cet objet est déjà financé !</>;
    case "NO CONTRIBUTIONS":
      return <ContributionForm/>;
  }
};

export default ContributionPanel