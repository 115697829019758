import React, {createContext, FC, useContext, useState} from "react";
import {SortOrder} from "../components/SortLink";

export interface IOrderByContext {
  order: string
  setOrder: (s: string) => void
}

export const OrderByContext = createContext<IOrderByContext>({} as IOrderByContext);

export const OrderByContextProvider: FC = ({children}) => {
  const [order, setOrder] = useState<string>('totalPrice_DESC');
  const context = {order, setOrder};
  return (
    <OrderByContext.Provider value={context}>
      {children}
    </OrderByContext.Provider>
  );
};

export const useOrder = () => {
  const {order, setOrder} = useContext<IOrderByContext>(OrderByContext);
  const [direction, setOrderDirection] = useState<SortOrder>('_DESC');
  const orderField = order && order.substr(0, order.indexOf('_'));

  return {
    order,
    direction,
    isActive: (field: string) => orderField === field,
    changeOrder: (field: string) => {
      if (field === orderField) {
        const isDesc = direction === '_DESC';
        setOrderDirection(isDesc ? '_ASC' : '_DESC');
        setOrder(field + (isDesc ? '_ASC' : '_DESC'));
      } else {
        setOrderDirection('_DESC');
        setOrder(field + '_DESC');
      }
    }
  }
};