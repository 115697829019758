import {getRemainingPrice, ListItem} from "../../models/ListItem";
import {useContext} from "react";
import {ContributorContext} from "../../contexts/ContributorContext";
import {Contribution} from "../../models/Contribution";

export type ContributionStatusType =
  | "FULLY CONTRIBUTED"
  | "FINALIZED BY CURRENT USER"
  | "STARTED BY CURRENT USER"
  | "NO CONTRIBUTIONS"

export const getContributorContributions = (item: ListItem, email: string) => {
  const items = (item.contributions || [])
    .sort((a, b) => (a.isFinalized ? 1 : 0) - (b.isFinalized ? 1 : 0))
    .filter(c => c.contributor.email === email);

  return {
    items,
    pending: items.filter((i: Contribution) => !i.isFinalized),
    finalized: items.filter((i: Contribution) => i.isFinalized),
  }
};

const useContributionStatus = (item: ListItem): ContributionStatusType => {
  const {contributor} = useContext(ContributorContext);
  const status = getContributorContributions(item, contributor.email);

  if (status.finalized.length > 0) {
    return "FINALIZED BY CURRENT USER"
  }
  if (status.pending.length > 0) {
    return "STARTED BY CURRENT USER"
  }
  if (getRemainingPrice(item) <= 0) {
    return "FULLY CONTRIBUTED"
  }
  return "NO CONTRIBUTIONS"
}

export default useContributionStatus