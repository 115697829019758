import React, {FC, useContext} from "react";
import {WishlistContext} from "../../contexts/WishlistContext";
import {Link} from "react-navi";
import {useMedia} from "react-use";

const HeroBody: FC = () => {
  const list = useContext(WishlistContext);
  const isWide = useMedia('(min-width: 780px)');

  return (
    <div className="hero-body">
      <div className="container">
        <div className="columns ">

          <div className="column" style={{overflow:"hidden", position: "relative"}}>
            <figure className="image" style={isWide ? {position: 'absolute', width: '100%'} : {}}>
              <img src="/images/lia-julien.jpg" alt="Top"/>
            </figure>
          </div>

          <div className="column">
            <div className="container">
              <h1 className="title"><Link href="/lia-julien">{list.title}</Link></h1>
              <h2 className="subtitle"><span dangerouslySetInnerHTML={{__html: list.subtitle}}/></h2>
              <article className="message is-primary">

                <div className="message-body" style={{paddingBottom: '2rem'}}>
                  <span dangerouslySetInnerHTML={{__html: list.message}}/><br/>
                  <span className="is-pulled-right has-text-weight-bold">&mdash; {list.authorNames}</span>
                </div>

              </article>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
};

export default HeroBody