import React, {useContext} from "react";
import {useConfirmReservation} from "../../../models/Reservation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {ReservationContext} from "../../../contexts/ReservationContext";

const ConfirmButton: React.FC<{ reservationId: string }> = ({reservationId}) => {
  const [{fetching, error}, confirmMutation] = useConfirmReservation();
  const {confirm: confirmContext} = useContext(ReservationContext);

  if (error) {
    return <>Erreur :(</>;
  }

  const onClick = () => {
    confirmMutation({id: reservationId})
      .then(confirmContext);
  };

  return (
    <button
      onClick={onClick}
      className={"button is-primary is-rounded" + (fetching ? " is-loading " : "")}>
      <span className="icon"><FontAwesomeIcon icon={faCheck}/></span>
      <span>Oui</span>
    </button>
  )
};

export default ConfirmButton