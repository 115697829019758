import {ListItem} from "./ListItem";
import {Contributor} from "./Contributor";
import {useMutation} from "urql";

type DateTime = string;

export interface Contribution {
  id: string
  item: ListItem
  contributor: Contributor
  message: string
  amount: number
  isFinalized: boolean
  createdAt: DateTime
  updatedAt: DateTime
}

interface CreateContributionVariables {
  itemId: string
  amount: number
  email: string
  message?: string
}

const createContributionMutation = `
mutation($itemId: ID!, $amount: Float!, $email: String!, $message: String) {
  createContribution(
    data: {
      item: { connect: { id: $itemId } }
      contributor: { connect: { email: $email } }
      message: $message
      amount: $amount
      isFinalized: false
    }
  ) {
    id
    amount
    isFinalized
    contributor {
      id
      email
      name
      phone
    }
  }
}
`;

export const useCreateContribution = () => {
  return useMutation<Contribution, CreateContributionVariables>(createContributionMutation);
};

const confirmContributionMutation = `
mutation($id: ID!) {
  updateContribution(
    data: { isFinalized: true }
    where: { id: $id }
  ) {
    id
  }
}
`;

export const useConfirmContribution = () => {
  return useMutation<{ id: string }, { id: string }>(confirmContributionMutation);
};

const deleteContributionMutation = `
mutation($id: ID!) {
  deleteContribution(where: { id: $id }) {
    id
  }
}
`;

export const useDeleteContribution = () => {
  return useMutation<{ id: string }, { id: string }>(deleteContributionMutation);
};