import React, {FC, Suspense, useEffect, useState} from 'react';
import {mount, redirect, route} from 'navi'
import {NotFoundBoundary, Router, View} from 'react-navi'
import '../App.scss';
import Wishlist from "../screens/Wishlist";
import {PrismaProvider} from "../contexts/PrismaProvider";
import Loading from "../screens/Loading";
import NotFound from "../screens/NotFound";
import Deconnexion from "../screens/Deconnexion";
import Connexion from "../screens/Connexion";
import CountDown from "./CountDown";
import moment from 'moment'
import {RELEASE_DATE} from "../constants";
import publicIp from 'public-ip'

const routes =
  mount({
    '/': redirect('/lia-julien'),
    '/lia-julien': route({view: <Wishlist/>}),
    '/lia-julien/i/:id': route({view: <Wishlist/>}),
    '/lia-julien/t/:token': route({view: <Connexion/>}),
    '/deconnexion': route({view: <Deconnexion/>})
  });

const App: FC = () => {
  const [showCountdown, setShowCountdown] = useState(true);
  useEffect(() => {
    publicIp.v4().then(ip => {
      if ('88.191.130.19' === ip) {
        setShowCountdown(false);
      } else {
        console.log(ip);
      }
    })
  })
  if (showCountdown && moment().diff(new Date(RELEASE_DATE)) < 0) {
    return <Loading><CountDown/></Loading>
  }
  return (
    <Router routes={routes} hashScrollBehavior="smooth">
      <NotFoundBoundary render={() => <NotFound/>}>
        <Suspense fallback={<Loading/>}>
          <PrismaProvider>
            <View/>
          </PrismaProvider>
        </Suspense>
      </NotFoundBoundary>
    </Router>
  );
};

export default App;
