import {ListItem} from "../../models/ListItem";
import {useCallback, useContext, useEffect, useState} from "react";
import {ReservationContext} from "../../contexts/ReservationContext";
import {ContributorContext} from "../../contexts/ContributorContext";

export type ReservationStatusType =
  | "FINALIZED BY CURRENT USER"
  | "FINALIZED BY SOMEONE"
  | "STARTED BY CURRENT USER"
  | "STARTED BY SOMEONE"
  | "NOT RESERVED"

export const hasFinalizedReservation = (item: ListItem) => !!(item.reservation?.id && item.reservation?.isFinalized);
export const hasStartedReservation = (item: ListItem) => !!(item.reservation?.id);

const useReservationStatus = (item: ListItem): ReservationStatusType => {
  const {contributor} = useContext(ContributorContext)

  const {
    item: contextItem,
    hasStarted: contextHasStarted,
    hasFinalized: contextHasFinalized
  } = useContext(ReservationContext);

  const [hasStarted, setHasStarted] = useState(contextHasStarted);
  const [hasFinalized, setHasFinalized] = useState(contextHasFinalized);
  const [contributorIsUser, setContributorIsUser] = useState(item.reservation !== null
    && item.reservation.contributor.email === contributor.email);

  const updateStatus = useCallback(() => {
    if (item.id === contextItem.id) {
      setHasStarted(contextHasStarted);
      setHasFinalized(contextHasFinalized);
      setContributorIsUser(contextItem.reservation !== null
        && contextItem.reservation.contributor.email === contributor.email);
    } else {
      setHasStarted(hasStartedReservation(item));
      setHasFinalized(hasFinalizedReservation(item));
      setContributorIsUser(false);
    }
  }, [
    contextHasFinalized,
    contextHasStarted,
    contributor.email,
    contextItem,
    item
  ]);

  useEffect(() => {
    updateStatus()
  }, [
    updateStatus
  ])

  if (contributorIsUser) {
    if (hasFinalized) {
      return "FINALIZED BY CURRENT USER"
    }
    if (hasStarted) {
      return "STARTED BY CURRENT USER"
    }
  }

  if (hasFinalized) {
    return "FINALIZED BY SOMEONE"
  }
  if (hasStarted) {
    return "STARTED BY SOMEONE"
  }

  return "NOT RESERVED"
}

export default useReservationStatus;