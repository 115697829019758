import localforage from 'localforage'
import React, {FC, useEffect, useState} from "react";
import {Contributor} from "../models/Contributor";

interface IContributorContext {
  contributor: Contributor
  setContributor: (c: Contributor) => void
  isLogged: boolean
}

export const ContributorContext = React.createContext<IContributorContext>({} as IContributorContext);

export const ContributorContextProvider: FC = ({children}) => {

  const [contributor, setContributor] = useState<Contributor>({} as Contributor);

  useEffect(() => {
    localforage.getItem<Contributor>('contributor')
      .then((c: Contributor) => {
        c && setContributor(c)
      });
  }, []);

  const context = {
    contributor,
    setContributor: (c: Contributor) => {
      localforage.setItem('contributor', c);
      setContributor(c)
    },
    isLogged: !!(contributor && contributor.email)
  };
  return (
    <ContributorContext.Provider value={context}>
      {children}
    </ContributorContext.Provider>
  )
};