import React, {FC, useContext} from "react";
import {ModalContext} from "../contexts/ModalContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGift} from "@fortawesome/free-solid-svg-icons";

const Panel: FC<{ title: string }> = ({title, children}) => {
  const {closeModal} = useContext(ModalContext);
  return (
    <article className="panel is-link">
      <p className="panel-heading">
        <FontAwesomeIcon icon={faGift}/>&nbsp;{title}
        <button className="delete is-pulled-right" onClick={closeModal}/>
      </p>
      <div className="card">
        <div className="card-content">
          {children}
        </div>
      </div>
    </article>
  );
};

export default Panel
