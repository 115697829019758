import React, {FC} from "react";

const LinkButton: FC<{ loading?: boolean, onClick: () => void }> = ({loading = false, onClick, children}) => {
  return (
    <button className={"button is-text" + (loading ? " is-loading " : "")} onClick={onClick}>
      {children}
    </button>
  )
};

export default LinkButton