import {Contribution} from "./Contribution";
import {Reservation} from "./Reservation";
import {Wishlist} from "./Wishlist";
import {useQuery, useSubscription} from "urql";

export interface ListItem {
  id: string;
  title: string;
  message: string;
  totalPrice: number;
  linkUrl: string;
  imageUrl: string | null;
  list: Wishlist;
  contributions: Contribution[];
  reservation: Reservation | null;
  reservationOnly: boolean;
}

export const getRemainingPrice = (item: ListItem) => {
  if (!item.contributions) {
    return item.totalPrice;
  }
  return item.totalPrice - item.contributions
    .reduce((res, next) => res + next.amount, 0);
};

const baseQuery = `
  listItems(orderBy: $orderBy, where: { list: { id: $listId } }) {
    id
    title
    message
    totalPrice
    linkUrl
    imageUrl
    reservationOnly
    list {
      id
      authorNames
    }
    contributions {
      id
      amount
      message
      isFinalized
      contributor {
        name
        createdAt
        email
      }
    }
    reservation {
      id
      message
      isFinalized
      contributor {
        name
        createdAt
        email
      }
    }
  }
`;

export const query = `
query GetListItems($listId: ID!, $orderBy: ListItemOrderByInput!) {
  ${baseQuery}
}
`;

export const subscription = `
subscription GetListItems($listId: ID!, $orderBy: ListItemOrderByInput!) {
  ${baseQuery}
}
`;

export const useWishListItems = (listId: string, orderBy: string) => {
  const [{data: initialItems, fetching: queryFetching}] = useQuery({
    query: query,
    variables: {listId, orderBy}
  });

  const [subscriptionResult] = useSubscription({
    query: subscription,
    variables: {listId},
    pause: queryFetching,
    // @ts-ignore
  }, (prev = initialItems, item) => setItems([...prev.items, item]));


  if (queryFetching) return [];

  const {listItems} = subscriptionResult.data || initialItems;
  return listItems;
};
